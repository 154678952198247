<template>
  <div id="app">
    <HeaderAndFooter>
      <About />
    </HeaderAndFooter>
  </div>
</template>

<script>
  import HeaderAndFooter from '@components/HeaderAndFooter'
  import About from '@components/About'

  export default {
    components: {
      About,
      HeaderAndFooter
    }
  }
</script>

<style lang="less">
  #app {
    background-color: #05374e;
    min-width: 1170px;
  }

  .hide {
    visibility: hidden;
  }
</style>
