<template>
  <div class="about_shenji">
    <div class="about_shenji_left">
      <img src="../../assets/imgs/about_shenji.png" alt="" />
    </div>

    <div class="about_shenji_right">
      <header class="about_shenji_right_top">
        <h1 class="hide">About SineuGene</h1>
      </header>
      <nav class="about_shenji_right_title">{{ $t('aboutShenjiChanghua') }}</nav>
      <main class="about_shenji_right_detail">
        <p>
          {{ $t('aboutShenjiChanghuaDesc') }}
        </p>
      </main>
    </div>
  </div>
</template>

<script>
  export default {
    // watch: {
    //   '$i18n.locale': {
    //     deep: true,
    //     immediate: true,
    //     handler(v) {
    //       document.title =
    //         v == 'en'
    //           ? `SineuGene Therapeutics Co.,ltd.- ${this.$t('aboutShenjiChanghua')}`
    //           : `神济昌华（北京）生物科技有限公司-${this.$t('aboutShenjiChanghua')}`
    //     }
    //   }
    // }
  }
</script>

<style lang="less" scoped>
  .about_shenji {
    display: flex;
    margin-bottom: 105px;

    .about_shenji_left {
      width: 554px;
      height: 367px;
      img {
        width: 100%;
      }
    }

    .about_shenji_right {
      min-width: 387px;
      flex: 1;
      margin-left: 65px;
      .about_shenji_right_top {
        position: relative;
        h1 {
          font-size: 37px;
          font-weight: bolder;
          line-height: 27px;
          color: #206ed2;
        }
        .to_detail_btn {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          width: 108px;
          height: 42px;
          text-align: center;
          line-height: 38px;
          border: 2px solid #206ed2;
          box-sizing: border-box;
          font-size: 16px;
          color: #206ed2;
        }
      }

      .about_shenji_right_title {
        font-size: 30px;
        margin-top: 25px;
        line-height: 1;
        color: #333;
      }

      .about_shenji_right_detail {
        margin-top: 25px;
        font-size: 14px;
        p {
          margin-bottom: 20px;
          line-height: 1.8;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
