import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import '@plugins/element.js'
import '@assets/css/reset.css'
import { Row, Col } from 'element-ui'
import i18n from '@/language/index.js'
Vue.use(Row)
Vue.use(Col)

Vue.config.productionTip = false

new Vue({
  i18n,
  render: (h) => h(App)
}).$mount('#app')
